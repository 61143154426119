<template>
  <div class="welcome-container">
    <div class="welcome-box">
      <div class="bg-blur" />
      <div class="content">
        <h1>Welcome to Adhere.ly 👋</h1>
        <p>Let's get started. You can now add your first client.</p>
        <div class="buttons">
          <v-btn
            x-large
            elevation="0"
            class="ma-0 mb-4 white--text"
            @click="add"
          >
            Add your first client
          </v-btn>
          <v-btn
            x-large
            elevation="0"
            color="white"
            @click="skip"
          >
            <span class="skip-for-now">
              Skip for now
            </span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FirstLogin',
  computed: mapGetters('authentication', ['providerTitle']),
  methods: {
    skip() {
      this.$router.replace({
        name: 'dashboard'
      })
    },
    add() {
      this.$router.replace({
        name: 'clientAdd',
        query: {
          first: 'true'
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">

// $color__background: #E5E5E5;
$color__base-text: #27201F;
$color__subheader-text: #615C5C;

.skip-for-now {
  color: #615C5C;
}

.welcome {

  &-container {
    display: flex;
    flex-direction: column;
    
    height: 100vh;

    // background-color: $color__background;
    background-color: #fff;
    background-image: url('~@/assets/gradient-bg.svg');
    background-blend-mode: add;
    background-size: cover;
    background-position: center;
  }

  &-box {
    display: inline-block;
    position: relative;
    width: 520px;
    margin: auto;

    .bg-blur {
      z-index: 1;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: #fff;
      filter: blur(56px);
    }

    .content {
      position: relative;
      padding: 72px 0px;
      z-index: 2;
      text-align: center;

      h1 {
        font-size: 24px;
        line-height: 22px;
        margin: 0px 0px 16px 0px;
        color: $color__base-text;
      }
  
      p {
        font-size: 14px;
        line-height: 24px;
        color: $color__subheader-text;
        margin: 0px 0px 32px 0px;
      }

      .buttons {
        width: 235px;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        
        button {
          text-transform: none;

          &.white {
            border: 1px solid #E0E0E0 !important;
            background-color: #fff;
          }
        }

      }
    }

  }
}
</style>
